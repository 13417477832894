.services {
    background: #fff;
    padding: 4rem;
    min-height: 590px;
}

.services-list {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3,minmax(0,1fr));
    padding: none;
    margin: none;
}

.service__item {
    list-style: none;
    margin: 1rem;
}

.service__item__title {
    border-bottom: 3px #fc0 solid;
}

@media screen and (max-width: 1200px) {
    .services-list {
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
}

@media screen and (max-width: 820px) {
    .services-list {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0;
        padding-left: 0;
    }
    .services {
    }

    .service__item {
    }
}