.portal {
    background-color: #fff;
    padding: 4rem;
    min-height: 590px;
}

.portal form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
}

.portal .form-row {
    margin: 2rem;
    display: flex;
    width: 100%;
}

.portal .form-row > label {
    display: inline;
    padding: 8px 26px 8px 26px;
    width: 100%;
    text-align: center;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.portal .form-row > input {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 8px 26px;
    width: 100%;
}

.portal a {
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
}

@media screen and (max-width: 960px) {
    .portal .form-row {
        display: grid;
        width: 180px;
    }

    .portal form {
        width: 300px;
    }
}