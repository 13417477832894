body {
    font-family: 'Roboto Flex', sans-serif;
    background-color: #1c1b1b;
    min-width: 400px;
}

.center {
    text-align: center;
}

.services-page {
    background-color: #fff;
}