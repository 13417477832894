.contact-form-section {
    padding: 4rem;
    min-height: 418px;
    position: relative;
    overflow-x: hidden;
    /*background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%); */
}

.contact-form-section h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    width: 12rem;
    border-radius: 6px;
}

.contact-form-background-mask {
    z-index: -1;
    position: absolute;
    top: -120px;
    left: 0;
    display: block;
    width: 1000px;
}

.contact-form-background {
    position: fixed;
    z-index: -2;
    object-fit: cover;
    top: 0;
    left: 0;
}

.contact-form {
    display: grid;
    grid-template-columns: 1fr 500px;
}

.contact-form h3.contact-form h5 {
    text-align: center;
}

.contact-form .btn {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contact-form form {
    columns: 2/2;
    max-width: 400px;
    padding: 2rem;
    font-size: 20px;
    box-shadow: 5px 5px 10px #000;
    background-color: #fff;
}


.contact-form .contact-details {
    columns: 1/1;
    margin-right: 3rem;
    margin-bottom: 3rem;
}

.contact-form form .contact-form-row {
    display: grid;
    column-gap: 70px;
    width: 100%;
}

.contact-form form .contact-form-row > label {
    display: inline;
    padding: 8px 26px 8px 26px;
    text-align: left;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Roboto Flex', sans-serif;;
    font-size: 1rem;
}

.contact-form form .contact-form-row > input, .contact-form form .contact-form-row > select, .contact-form textarea {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 8px 26px;
    border: none;
    border-bottom: 3px #fc0 solid;
    background-color: transparent;
    font-family: 'Roboto Flex', sans-serif;;
    font-size: 1rem;
    background-color: #fcfcfc;
}

.contact-form textarea {
    width: 345px;
    min-height: 100px;
    resize: vertical;
}

.contact-form .contact-body {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 8px 26px;
    border-radius: 4px;
}

.hidden {
    visibility: hidden;
    display: none;
    position: fixed;
}

.contact-icon {
    background-color: #fc0;
    border-radius: 100%;
    padding: 5px;
}

img.contact-icon {
    display: inline;
    position: relative;
    top: 6px;
}

@media screen and (max-width: 960px) {
    .contact-form {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .contact-form form {
        width: 100%;
        padding: 2rem;
        font-size: 20px;
    }
    
    .contact-form textarea {
        width: 206px;
    }
}