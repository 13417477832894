.about {
    background: #fff;
    padding: 4rem;
    margin-bottom: 0;
}

.about-text {
    padding: 2rem;
    grid-column: 1 / 3;
    width: 100%;
}

.about-group {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3,auto);
}

.about-image-container {
    padding: 1rem;
    grid-column: 3 / 3;
}

.about-image-container > img {
    width: 100%;
    border-radius: 6px;
}

.about-text h4 {
    border-bottom: 3px #fc0 solid;
    width: fit-content;
    padding-right: 1rem;
}


@media screen and (max-width: 1000px) {

    .about-group {
        display: flex;
        flex-wrap: wrap;
        max-height: fit-content;
    }
    
}

@media screen and (max-width: 820px) {
    
    .about-text {
        padding: 2rem;
        grid-column: none;
        width: 100%;
    }
}