.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  margin: 50px 0 45px;
}

.cards__items {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 24px;
  justify-content: space-evenly;
  flex-basis: auto;
}

.cards__item {
  display: flex;
  margin: 1rem 1rem;
  border-radius: 10px;
  width: 200px;
  height: 280px;
  transform: scale(1.0);
  transition: all 250ms ease;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__ace-top {
  color: #000;
  position: absolute;
  z-index: 999;
  top: -5px;
  left: 15px;
}

.cards__item__ace-bottom {
  color: #000;
  position: absolute;
  z-index: 999;
  bottom: -5px;
  right: 15px;
  transform: rotate(180deg);
}

.cards__item__ace-top > img, .cards__item__ace-bottom > img {
  position: absolute;
  transform:
    translate(-34%, -75%) 
    scale(0.6);
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: -1px;
  left: -12px;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: #fc0;
  box-sizing: border-box;
  border-radius: 10px ;
}

.cards__item__pic-wrap[data-category="Project"]::after {
  background-color: rgba(0, 128, 0, 0.75);
}

.cards__item__pic-wrap[data-category="Software"]::after {
  background-color: rgba(128, 0, 128, 0.75);
}

.cards__item__pic-wrap[data-category="Client"]::after {
  background-color: rgba(0, 0, 128, 0.75);
}

.cards__item__pic-wrap[data-category="DevOps"]::after {
  background-color: rgba(218, 165, 32, 0.75);
}

.cards__item__pic-wrap:not([data-category])::after {
  display: none;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img[use-image-width="true"] {
  width: 75%;
  padding-left: 12%;
  padding-top: 30%;
  height: auto;
}

.cards__item:hover {
  transform: scale(1.2);
  transition: all 250ms ease;
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #000;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  z-index: 5;
  position: absolute;
  top: 30px;
  left: 0px;
  height: 50px;
  padding: -10px 4px;
  transition: all 250ms ease;
  background-color: rgba(255,255,255,0.3);
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
