.company-values {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.company-values-background {
    object-fit: cover;
    z-index: -1;
    position: fixed;
    top: 300px;
    right: 50px;
}

.company-value-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.company-values > h1 {
    color: #fff;
}

.company-value {
    color: #fc0;
    margin: auto;
    font-weight: 100;
}


@media screen and (max-width: 1000px) {
    .company-value-group {
        flex-direction: column;
    }
    
    .company-value {
        color: #fc0;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }

}