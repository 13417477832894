:root {
    --primary: #ffcc00;
}

.btn {
    padding: 8px 20px;
    border-radius: 3px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--primary:hover {
    background-color: transparent;
    color: #ffcc00;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline {
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline:hover {
    background-color: var(--primary);
    color: #000;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 8px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn-large:hover {
    background-color: var(--primary);
    color: #242424;
    transition: all 0.3s ease-out;
}