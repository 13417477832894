.meet-the-team-section {
    background: #fff;
    margin: 0;
    padding: 4rem;
}

.team {
    display: grid;
    grid-template-columns: repeat(2,auto);
    column-gap: 50px;
}

.team-member {
    display: grid;
    column-gap: 50px;
    column-count: 2;
    grid-template-columns: repeat(3,auto);
    max-width: 800px;
}

.team-member-bio > h2 {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 3px #fc0 solid;
}

.team-member-bio > h4 {
    margin-top: 0;
}

.team-member-image-container {
    columns: 1/1;
    max-width: 300px;
}

.team-member-bio {
    columns: 2/3;
}

.team-member-image-container > img {
    width: 100%;
    border-radius: 6px;
}



@media screen and (max-width: 1000px) {
    .team {
        display: flex;
        flex-wrap: wrap;
    }

    .team-member {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 820px) {
    .team-member {
        display: flex;
        flex-wrap: wrap;
    }

    .team-member-image-container {
        margin-left: auto;
        margin-right: auto;
    }

    .team-member-bio {
        margin-left: auto;
        margin-right: auto;
    }
}